// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAL2vun6QoLk9HpinNJD6aixum2FMnI6Oc",
  authDomain: "grace-50eeb.firebaseapp.com",
  projectId: "grace-50eeb",
  storageBucket: "grace-50eeb.appspot.com",
  messagingSenderId: "292371183786",
  appId: "1:292371183786:web:5c7cd32eac3e95fa9238d3",
  measurementId: "G-Y79L7HKJQB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const DB = getFirestore(app);
export { DB };
