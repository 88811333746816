import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useAddSectionMutation } from "../../store/verses/service";

export const AdminSections2 = () => {
  const [newSection, setNewSection] = useState({
    english: "",
    malayalam: "",
    tags: [],
  });
  const [addSection, { isLoading: isAddingSection }] = useAddSectionMutation();

  const handleAddSection = async () => {
    console.log({ newSection });
    if (newSection.english.trim() && newSection.malayalam.trim()) {
      const sectionTitle = newSection?.english || "";
      const updatedData = { ...newSection, value: sectionTitle.toLowerCase() };
      await addSection(updatedData);
      setNewSection({ english: "", malayalam: "", tags: [] });
      alert("New section added");
    } else {
      alert("enter all fields");
    }
  };

  return (
    <>
      {/* Dialog for adding new section */}
      <Dialog open={true} onClose={() => {}}>
        <DialogTitle>Add New Section</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="English"
            fullWidth
            variant="outlined"
            value={newSection.english}
            onChange={(e) =>
              setNewSection({ ...newSection, english: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Malayalam"
            fullWidth
            variant="outlined"
            value={newSection.malayalam}
            onChange={(e) =>
              setNewSection({ ...newSection, malayalam: e.target.value })
            }
          />
          <Grid item xs={12} mt={1}>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              onChange={(_, value) => {
                setNewSection((prev) => ({ ...prev, tags: value }));
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Tags"
                  placeholder="Enter tags"
                />
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddSection} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
